/* GAME PAGE */
@font-face {
  font-family: Chilling;
  src: local(Chilling), url('./assets/fonts/Chilling.woff') format('woff'), 
        url('./assets/fonts/Chilling.ttf') format('truetype'); 
}

@font-face {
  font-family: KomikaDisplay-Bold;
  src: local(KomikaDisplay-Bold), url('./assets/fonts/KomikaDisplay-Bold.woff') format('woff'), 
        url('./assets/fonts/KomikaDisplayBold.ttf') format('truetype'); 
}

/* ABOUT & CONTACT PAGES */
@font-face {
  font-family: Shagadelic;
  src: local(Shagadelic), url('./assets/fonts/ShagadelicBold.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
       url('./assets/fonts/Shagadelic_Bold.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}  

@font-face {
  font-family: SpicyRice;
  src: local(SpicyRice), url('./assets/fonts/SpicyRice.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
       url('./assets/fonts/SpicyRice.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
} 

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div.game-copy  > h1 {
  font-family: Chilling !important;
}

div.game-copy  > p, i.question {
  font-family: KomikaDisplay-Bold !important;
}

button.game-button {
  font-family: Chilling !important;
}

div.about-copy > h1, div.contact-copy > h1 {
  font-family: Shagadelic !important;
  letter-spacing: 4px;
  font-size: 42px;
}

div.about-copy  > p {
  font-family: SpicyRice !important;
  font-size: 20px !important;
}


div.contact-copy  > p {
  font-family: SpicyRice !important;
  font-size: 20px !important;
}

div.heading-copy > h6 {
  font-family: Shagadelic !important;
  font-size: 26px;
}

button.menu-button {
  font-family: SpicyRice !important;
  letter-spacing: 3px;
  font-size: 18px !important;
}
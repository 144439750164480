.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@keyframes animated_text {
	0% { background-position: 0px 50%; }
	50% { background-position: 100% 50%; }
	100% { background-position: 0px 50%; }
}

.game-gif {
  box-shadow: inset 0 0 4em 4em #100e24;
  background: url("/src/assets/images/the_witching_hour.gif") center no-repeat;
  background-size: cover;
}

.about-wrapper {
  background: linear-gradient( rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.4)), url("/src/assets/images/studio_background.png") center no-repeat;
  background-size: cover;
}

.about-copy {
  padding: 20px;
  margin: 0px 20px 0px 20px !important;
  background-color: rgba(36, 30, 78, 0.9);
  border-radius: 10px;
}

.about-copy > h1, .heading-copy > h6 {
  background: linear-gradient(-45deg,#a4e9f9, #c5aef2, #8578ea, #fff1bf);
	background-size: 300%;
	-webkit-background-clip: text;
  background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: animated_text 10s ease-in-out infinite;
	-moz-animation: animated_text 10s ease-in-out infinite;
	-webkit-animation: animated_text 10s ease-in-out infinite;
}

.heading-copy > h6 {
  background: linear-gradient(-45deg,#84ffc9, #eca0ff, #f9c58d, #fff1bf);
	background-size: 300%;
	-webkit-background-clip: text;
  background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: animated_text 10s ease-in-out infinite;
	-moz-animation: animated_text 10s ease-in-out infinite;
	-webkit-animation: animated_text 10s ease-in-out infinite;
}

.heading-copy > h6.company-name-part-2 {
  margin-top: 0px !important;
}

.contact-gif {
  background: url("/src/assets/images/company.gif") center no-repeat;
  background-size: cover;
  min-height: 65vh;
  min-width: 35vw;
  border-radius: 10px !important;
}

.contact-copy > h1, .email-link {
  background: linear-gradient(-45deg, #84ffc9, #aab2ff, #eca0ff, #f9c58d);
	background-size: 300%;
	-webkit-background-clip: text;
  background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: animated_text 10s ease-in-out infinite;
	-moz-animation: animated_text 10s ease-in-out infinite;
	-webkit-animation: animated_text 10s ease-in-out infinite;
}

.question {
  font-weight: bold !important;
}

.coming-soon-gif {
  background: url("/src/assets/images/coming_soon.gif") center no-repeat;
  background-size: cover;
  width: 45vw;
  margin-right: 10px;
}

button.game-button {
  background: linear-gradient(-45deg, #0b3866, #42047e, #8711c1, #bc1b68);
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: animated_text 10s ease-in-out infinite;
  letter-spacing: 2px;
}

.anim-bg-gradient:hover {
  background-position: 0 0;
}

@media only screen and (max-width: 1000px) and (min-width: 400px)  {
  .about-copy {
    width: 90vw !important;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  } 
}

@media only screen and (max-width: 1200px) and (min-width: 400px)  {
  .about-copy {
    width: 95vw !important;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  } 
}

@media only screen and (max-width: 399px) {
  .about-copy {
    width: 95vw !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  } 
  div.game-copy > p, i.question {
    font-size: 18px !important;
  }
}

